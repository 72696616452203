import { createRoot } from 'react-dom/client';

import { App } from './components/common/App/App';
import './style/index.css';

let rootElement = null;
if (document.getElementById('root-web') !== null) {
  rootElement = document.getElementById('root-web');
} else if (document.getElementById('root-gdoc') !== null) {
  rootElement = document.getElementById('root-gdoc');
} else if (document.getElementById('root') !== null) {
  rootElement = document.getElementById('root');
} else if (document.getElementById('root-word') !== null) {
  rootElement = document.getElementById('root-word');
}

createRoot(rootElement).render(<App />);
